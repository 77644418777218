import React, { useState, useEffect } from 'react';
import * as XLSX from 'xlsx';

const QuizApp = () => {
  const [questions, setQuestions] = useState([]);
  const [currentQuestionIndex, setCurrentQuestionIndex] = useState(0);
  const [selectedAnswer, setSelectedAnswer] = useState('');
  const [showHint, setShowHint] = useState(false);
  const [feedback, setFeedback] = useState('');
  const [showInstructions, setShowInstructions] = useState(false);
  const [correctCount, setCorrectCount] = useState(() => Number(localStorage.getItem('correctCount')) || 0);
  const [incorrectCount, setIncorrectCount] = useState(() => Number(localStorage.getItem('incorrectCount')) || 0);
  const [isAnswered, setIsAnswered] = useState(false);

  const resetQuizState = () => {
    setCurrentQuestionIndex(Math.floor(Math.random() * questions.length));
    setSelectedAnswer('');
    setFeedback('');
    setShowHint(false);
    setIsAnswered(false);
  };

  const handleFileUpload = (event) => {
    const file = event.target.files[0];
    const reader = new FileReader();
    reader.onload = (e) => {
      const data = new Uint8Array(e.target.result);
      const workbook = XLSX.read(data, { type: 'array' });
      const sheetName = workbook.SheetNames[0];
      const sheet = workbook.Sheets[sheetName];
      const parsedQuestions = XLSX.utils.sheet_to_json(sheet);

      // Normalize the section names to be consistent
      const normalizedQuestions = parsedQuestions.map(q => ({
        ...q,
        Section: q.Section.trim().toLowerCase()
      }));

      setQuestions(normalizedQuestions.sort(() => Math.random() - 0.5));
      resetQuizState();
    };
    reader.readAsArrayBuffer(file);
  };

  const handleAnswer = (answer) => {
    const currentQuestion = questions[currentQuestionIndex] || {};
    const correctAnswer = currentQuestion['Correct Answer']?.trim();
    const isCorrect = answer.trim() === correctAnswer;
    setSelectedAnswer(answer);
    setFeedback(isCorrect ? 'Correct!' : 'Incorrect');

    if (!isAnswered) {
      if (isCorrect) {
        setCorrectCount(prev => {
          const updatedCount = prev + 1;
          localStorage.setItem('correctCount', updatedCount);
          return updatedCount;
        });
      } else {
        setIncorrectCount(prev => {
          const updatedCount = prev + 1;
          localStorage.setItem('incorrectCount', updatedCount);
          return updatedCount;
        });
      }
      setIsAnswered(true);
    }
  };

  const handleNextQuestion = () => {
    setCurrentQuestionIndex(Math.floor(Math.random() * questions.length));
    setSelectedAnswer('');
    setFeedback('');
    setShowHint(false);
    setIsAnswered(false);
  };

  const toggleHint = () => {
    setShowHint(prevShowHint => !prevShowHint);
  };

  const toggleInstructions = () => {
    setShowInstructions(prevShow => !prevShow);
  };

  const handleResetScoreboard = () => {
    setCorrectCount(0);
    setIncorrectCount(0);
    localStorage.setItem('correctCount', 0);
    localStorage.setItem('incorrectCount', 0);
  };

  const currentQuestion = questions[currentQuestionIndex] || {};
  const totalQuestions = correctCount + incorrectCount;
  const scorePercentage = totalQuestions > 0 ? Math.round((correctCount / totalQuestions) * 100) : 0;

  return (
    <div style={{ padding: '20px', position: 'relative' }}>
      <h1>Quiz App</h1>
      <p>Upload your spreadsheet to start the quiz.</p>
      <input type="file" onChange={handleFileUpload} style={{ marginBottom: '20px' }} />
      <div style={{ marginBottom: '20px' }}>
        <button onClick={toggleInstructions} style={{ backgroundColor: '#555', color: 'white', padding: '5px 10px', border: 'none', borderRadius: '5px', cursor: 'pointer' }}>
          {showInstructions ? 'Hide Spreadsheet Structure' : 'Show Spreadsheet Structure'}
        </button>
        {showInstructions && (
          <div style={{ marginTop: '10px', padding: '10px', border: '1px solid #ccc', borderRadius: '5px', backgroundColor: '#f9f9f9', color: 'black' }}>
            <h3>Spreadsheet Structure</h3>
            <p style={{ color: '#333' }}>Ensure your spreadsheet has the following columns (order doesn't matter):</p>
            <ul>
              <li><strong>Question</strong>: The quiz question.</li>
              <li><strong>Answers</strong>: Possible answers separated by new lines.</li>
              <li><strong>Correct Answer</strong>: The correct answer.</li>
              <li><strong>More Info</strong>: Additional information or hints.</li>
              <li><strong>Section</strong>: The section or category of the question.</li>
            </ul>
          </div>
        )}
      </div>
      {questions.length === 0 ? (
        <p>No questions loaded. Please upload a spreadsheet.</p>
      ) : (
        <>
          {currentQuestion.Question ? (
            <div>
              <h2>{currentQuestion.Question}</h2>
              <p style={{ fontSize: '0.9em', color: '#333' }}>Section: <strong>{currentQuestion.Section}</strong></p>
              <div style={{ marginBottom: '20px' }}>
                {currentQuestion.Answers?.split('\n').map(answer => (
                  <button
                    key={answer}
                    onClick={() => handleAnswer(answer)}
                    style={{
                      display: 'block',
                      margin: '10px 0',
                      padding: '10px',
                      border: '1px solid #ccc',
                      borderRadius: '5px',
                      cursor: 'pointer',
                      backgroundColor: '#f9f9f9'
                    }}
                  >
                    {answer}
                  </button>
                ))}
              </div>
              {selectedAnswer && <p>{selectedAnswer}: {feedback}</p>}
              <div style={{ marginTop: '20px', display: 'flex', justifyContent: 'space-between' }}>
                <button onClick={toggleHint} style={{ backgroundColor: '#f0ad4e', color: 'white', padding: '10px 20px', border: 'none', borderRadius: '5px', cursor: 'pointer' }}>
                  {showHint ? 'Hide Hint' : 'Show Hint'}
                </button>
                {showHint && <p>{currentQuestion['More Info'] ? currentQuestion['More Info'] : 'N/A'}</p>}
                <button onClick={handleNextQuestion} style={{ backgroundColor: '#5bc0de', color: 'white', padding: '10px 20px', border: 'none', borderRadius: '5px', cursor: 'pointer' }}>
                  Next Question
                </button>
              </div>
            </div>
          ) : (
            <p>Loading...</p>
          )}
          <div style={{ position: 'absolute', top: '20px', right: '20px', width: '200px', padding: '10px', border: '1px solid #ccc', borderRadius: '5px', backgroundColor: '#fff', boxShadow: '0px 0px 10px rgba(0, 0, 0, 0.1)' }}>
            <h3 style={{ margin: '0 0 10px', color: '#333', textAlign: 'center' }}>Scoreboard</h3>
            <p style={{ margin: '5px 0', color: '#333' }}>Correct: {correctCount}</p>
            <p style={{ margin: '5px 0', color: '#333' }}>Incorrect: {incorrectCount}</p>
            <p style={{ margin: '5px 0', color: '#333' }}>Total Score: {scorePercentage}%</p>
            <button onClick={handleResetScoreboard} style={{ backgroundColor: '#d9534f', color: 'white', padding: '5px 10px', border: 'none', borderRadius: '5px', cursor: 'pointer', width: '100%' }}>
              Reset Scoreboard
            </button>
          </div>
        </>
      )}
    </div>
  );
};

export default QuizApp;
